// src/styles/FormSection2Styles.js
import { styled } from '@mui/material/styles';
import { Paper, Button } from '@mui/material';

export const StyledPaper = styled(Paper)(({ theme }) => ({
    padding: theme.spacing(4),
    textAlign: 'left',
    color: '#fff',
    backgroundColor: '#2c2c2c',
    marginBottom: theme.spacing(4),
}));

export const NextButton = styled(Button)(({ theme }) => ({
    marginTop: theme.spacing(2),
    backgroundColor: '#00796b',
    color: '#fff',
    '&:hover': {
        backgroundColor: '#004d40',
    },
}));

export const BackButton = styled(Button)(({ theme }) => ({
    marginTop: theme.spacing(2),
    marginRight: theme.spacing(2),
    backgroundColor: '#757575',
    color: '#fff',
    '&:hover': {
        backgroundColor: '#616161',
    },
}));
