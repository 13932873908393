// src/components/Disclaimer.jsx
import React from 'react';
import { Typography, Link, Box } from '@mui/material';
import { RootContainer, DisclaimerPaper, AcceptButton } from '../styles/DisclaimerStyles';

function Disclaimer({ onAccept }) {
    return (
        <RootContainer maxWidth="md">
            <DisclaimerPaper>
                {/* Logo Section */}
                <Box mb={3} display="flex" justifyContent="center">
                    <img 
                        src={`${process.env.PUBLIC_URL}/logo-old.png`} 
                        alt="CCRNet Logo" 
                        style={{ 
                            maxWidth: '100px', 
                            width: '100%',
                            height: 'auto' 
                        }} 
                    />
                </Box>

                <Typography variant="h5" component="h1" gutterBottom>
                    File a Complaint
                </Typography>
                <Typography variant="body1" component="p" gutterBottom>
                    Before submitting a complaint to CCRNet, please review the following terms and conditions. If you have additional questions, please refer to our <Link href="/faqs">FAQs</Link> for details on:
                </Typography>
                <ul style={{ textAlign: 'left', paddingLeft: '20px', paddingRight: '20px' }}>
                    <li>Information required for filing your complaint</li>
                    <li>What happens after your complaint is submitted</li>
                    <li>How complaints are investigated and resolved</li>
                    <li>The importance of retaining evidence related to your complaint</li>
                </ul>
                <Typography variant="body1" component="p" gutterBottom>
                    By submitting this complaint, I affirm that the information provided is accurate to the best of my knowledge. Providing false information may lead to the rejection of your complaint and may impact the outcome of any investigation.
                </Typography>
                <Typography variant="body1" component="p" gutterBottom>
                    CCRNet is a private organization. Complaints submitted through this platform may be shared with law enforcement agencies, private investigators, and research institutions for the purpose of identifying and investigating criminal activities. The decision to investigate any complaint is at the discretion of the receiving agency or entity.
                </Typography>
                <Typography variant="body1" component="p" gutterBottom>
                    Filing a complaint with CCRNet does not notify your financial institutions of any unauthorized transactions. Please contact your bank or credit card company directly to address such issues.
                </Typography>
                <Typography variant="body1" component="p" gutterBottom>
                    All information submitted is encrypted and protected via Secure Socket Layer (SSL) encryption. For further details on how we protect your data, please review our <Link href="/privacy-policy">Privacy Policy</Link>.
                </Typography>
                <Typography variant="body1" component="p" gutterBottom>
                    Thank you for your cooperation and commitment to helping us combat cybercrime.
                </Typography>
                <AcceptButton variant="contained" onClick={onAccept}>
                    I Accept
                </AcceptButton>
            </DisclaimerPaper>
        </RootContainer>
    );
}

export default Disclaimer;
