// src/components/FormSection1.jsx



import React from 'react';
import { Typography, TextField, MenuItem } from '@mui/material';
import { StyledPaper, NextButton } from '../styles/FormSection1Styles';

function FormSection1({ category, fraudType, incidentLocation, blockchainAddress, onCategoryChange, onFraudTypeChange, onIncidentLocationChange, onBlockchainAddressChange, onNext }) {
    const fraudTypes = [
        'Online Scams',
        'Credit Card Fraud',
        'Identity Theft',
        'Email Phishing',
        'Ransomware',
        'Investment Fraud',
        'Lottery Scams',
        'Romance Scams',
        'Business Email Compromise',
        'Social Media Fraud',
        'Darknet Vendor Fraud',
        'Darknet Market Fraud',
        'Darknet Identity Theft',
        'Darknet Ransomware',
        'Darknet Phishing',
        'Darknet Money Laundering',
        'Darknet Drug Trafficking',
        'Darknet Weapons Trafficking',
        'Darknet Exploit Sale',
        'Darknet Human Trafficking',
        'Crypto Scams', // Broader category for all crypto-related fraud
        'Cryptocurrency Investment Fraud',
        'Initial Coin Offering (ICO) Scams',
        'Crypto Ponzi Schemes',
        'Crypto Mining Scams',
        'Rug Pulls',
        'Crypto Phishing',
        'Fake Cryptocurrency Exchanges',
        'Crypto Wallet Theft',
        'Cryptojacking',
        'Pump and Dump Schemes',
    ];

    // Determine if the fraudType is crypto-related
    const isCryptoFraud = fraudType === 'Crypto Scams' || fraudTypes.some((type) =>
        type.toLowerCase().includes('crypto') && type === fraudType
    );

    return (
        <StyledPaper>
            <Typography variant="h5" gutterBottom>
                Who is Filing this Complaint?
            </Typography>
            <Typography variant="body1" gutterBottom>
                If you were the victim of this incident, please indicate so below. In the event that you are completing this form on behalf of another individual or business, please select NO and provide your contact information.
            </Typography>
            <TextField
                select
                label="Were you the victim in this incident?"
                value={category}
                onChange={(e) => onCategoryChange(e.target.value)}
                variant="outlined"
                fullWidth
                margin="normal"
                required
                InputLabelProps={{
                    style: { color: '#fff' },
                }}
                SelectProps={{
                    MenuProps: {
                        PaperProps: {
                            style: {
                                backgroundColor: '#2c2c2c',
                                color: '#fff',
                            },
                        },
                    },
                    sx: {
                        backgroundColor: '#2c2c2c',
                        color: '#fff',
                        '.MuiSelect-select': {
                            color: '#fff',
                        },
                        '& .MuiSvgIcon-root': {
                            color: '#fff',
                        },
                    },
                }}
                InputProps={{
                    style: { color: '#fff', backgroundColor: '#2c2c2c' },
                }}
            >
                <MenuItem value="yes">Yes</MenuItem>
                <MenuItem value="no">No</MenuItem>
            </TextField>
            <TextField
                select
                label="Type of Fraud"
                value={fraudType}
                onChange={(e) => onFraudTypeChange(e.target.value)}
                variant="outlined"
                fullWidth
                margin="normal"
                required
                InputLabelProps={{
                    style: { color: '#fff' },
                }}
                SelectProps={{
                    MenuProps: {
                        PaperProps: {
                            style: {
                                backgroundColor: '#2c2c2c',
                                color: '#fff',
                            },
                        },
                    },
                    sx: {
                        backgroundColor: '#2c2c2c',
                        color: '#fff',
                        '.MuiSelect-select': {
                            color: '#fff',
                        },
                        '& .MuiSvgIcon-root': {
                            color: '#fff',
                        },
                    },
                }}
                InputProps={{
                    style: { color: '#fff', backgroundColor: '#2c2c2c' },
                }}
            >
                {fraudTypes.map((type) => (
                    <MenuItem key={type} value={type}>
                        {type}
                    </MenuItem>
                ))}
            </TextField>
            <TextField
                label="Where did the incident take place? (e.g., website, domain, marketplace, chatroom)"
                value={incidentLocation}
                onChange={(e) => onIncidentLocationChange(e.target.value)}
                variant="outlined"
                fullWidth
                margin="normal"
                required
                InputLabelProps={{
                    style: { color: '#fff' },
                }}
                InputProps={{
                    style: { color: '#fff', backgroundColor: '#2c2c2c' },
                }}
            />
            {isCryptoFraud && (
                <TextField
                    label="Blockchain Address (optional)"
                    value={blockchainAddress}
                    onChange={(e) => onBlockchainAddressChange(e.target.value)}
                    variant="outlined"
                    fullWidth
                    margin="normal"
                    InputLabelProps={{
                        style: { color: '#fff' },
                    }}
                    InputProps={{
                        style: { color: '#fff', backgroundColor: '#2c2c2c' },
                    }}
                />
            )}
            <NextButton variant="contained" onClick={onNext}>
                Next (Step 2)
            </NextButton>
        </StyledPaper>
    );
}

export default FormSection1;
