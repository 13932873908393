// src/components/FormSection2.jsx
import React from 'react';
import { Typography, TextField } from '@mui/material';
import { StyledPaper, NextButton, BackButton } from '../styles/FormSection2Styles';

function FormSection2({ victimInfo, onVictimInfoChange, onNext, onBack }) {
    return (
        <StyledPaper>
            <Typography variant="h5" gutterBottom>
                Victim Information
            </Typography>
            <Typography variant="body1" gutterBottom>
                Please provide the following details about the victim.
            </Typography>
            <TextField
                label="Victim Name"
                value={victimInfo.name}
                onChange={(e) => onVictimInfoChange('name', e.target.value)}
                variant="outlined"
                fullWidth
                margin="normal"
                required
                InputLabelProps={{
                    style: { color: '#fff' }, // Label text color
                }}
                InputProps={{
                    style: { color: '#fff' }, // Input text color
                }}
            />
            <TextField
                label="Victim Email"
                value={victimInfo.email}
                onChange={(e) => onVictimInfoChange('email', e.target.value)}
                variant="outlined"
                fullWidth
                margin="normal"
                required
                InputLabelProps={{
                    style: { color: '#fff' }, // Label text color
                }}
                InputProps={{
                    style: { color: '#fff' }, // Input text color
                }}
            />
            <BackButton variant="contained" onClick={onBack}>
                Back (Step 1)
            </BackButton>
            <NextButton variant="contained" onClick={onNext}>
                Next (Step 3)
            </NextButton>
        </StyledPaper>
    );
}

export default FormSection2;
