import React from 'react';
import { Typography, ListItemText } from '@mui/material';
import { 
  StyledContainer, 
  StyledPaper, 
  StyledList, 
  StyledListItem, 
  StyledButton 
} from '../styles/IndustryReportsStyles';

function IndustryReports() {
  return (
    <StyledContainer maxWidth="lg">
      <StyledPaper>
        <Typography variant="h3" component="h1" gutterBottom>
          Industry Reports
        </Typography>
        <Typography variant="body1" gutterBottom>
          Understand the evolving cyber threats targeting businesses and how to mitigate risks. 
          CCRNet provides access to detailed reports, actionable insights, and investigative tools through 
          our mobile app to help organizations enhance their cybersecurity posture.
        </Typography>
        
        <Typography variant="h5" component="h2" gutterBottom>
          Recent Threats
        </Typography>
        <StyledList>
          <StyledListItem>
            <ListItemText
              primary="Ransomware Attacks on Small Businesses"
              secondary="In-depth analyses on emerging ransomware variants and best practices to prevent data encryption and financial loss."
            />
          </StyledListItem>
          <StyledListItem>
            <ListItemText
              primary="Phishing Scams Targeting Corporate Emails"
              secondary="Detailed methods to identify and block phishing attempts that aim to steal credentials and sensitive data."
            />
          </StyledListItem>
          <StyledListItem>
            <ListItemText
              primary="Insider Threats in the Workplace"
              secondary="Guidance on detecting unusual employee behavior, safeguarding intellectual property, and minimizing internal risks."
            />
          </StyledListItem>
          <StyledListItem>
            <ListItemText
              primary="Blockchain Security Challenges"
              secondary="A comprehensive look at vulnerabilities in decentralized platforms, smart contracts, and cryptocurrency-related infrastructures."
            />
          </StyledListItem>
          <StyledListItem>
            <ListItemText
              primary="Supply Chain Attacks"
              secondary="Recommendations on evaluating third-party vendors and preventing infiltration through compromised software updates."
            />
          </StyledListItem>
        </StyledList>
        
        <Typography variant="h5" component="h2" gutterBottom sx={{ marginTop: 4 }}>
          Mitigation Strategies
        </Typography>
        <StyledList>
          <StyledListItem>
            <ListItemText
              primary="Implement Zero-Trust Architectures"
              secondary="Reduce the attack surface by enforcing strict identity verification and minimal user privileges."
            />
          </StyledListItem>
          <StyledListItem>
            <ListItemText
              primary="Regular Security Training"
              secondary="Educate staff on the latest cyber threats, from phishing to deepfake impersonations, to foster a vigilant culture."
            />
          </StyledListItem>
          <StyledListItem>
            <ListItemText
              primary="Robust Incident Response Plans"
              secondary="Establish clear protocols for detecting, reporting, and responding to breaches, reducing impact and recovery time."
            />
          </StyledListItem>
          <StyledListItem>
            <ListItemText
              primary="Continuous Vulnerability Assessments"
              secondary="Regularly test and review your systems to identify and patch weaknesses before they are exploited."
            />
          </StyledListItem>
          <StyledListItem>
            <ListItemText
              primary="Leverage Threat Intelligence Feeds"
              secondary="Stay up-to-date with global threat trends and incorporate timely intelligence into your defense strategies."
            />
          </StyledListItem>
        </StyledList>

        <Typography variant="h5" component="h2" gutterBottom sx={{ marginTop: 4 }}>
          Additional Insights
        </Typography>
        <Typography variant="body1" gutterBottom>
          Explore region-specific trends, industry benchmarks, and expert analyses. Our reports compile data 
          from various sources, helping you understand global cyber landscapes. This holistic view ensures that 
          you can anticipate emerging threats and adapt your security measures accordingly.
        </Typography>
        
        <Typography variant="body2" component="p" gutterBottom>
          Regular updates and actionable insights can help your organization stay ahead of cyber threats. 
          Subscribe to receive alerts on newly discovered threats, best practices, and recommended tools. 
          For tailored guidance, contact our cybersecurity experts, and discover how CCRNet’s mobile app 
          can assist in investigations and threat monitoring.
        </Typography>
        
        <StyledButton variant="contained" href="/subscribe">
          Subscribe to Industry Alerts
        </StyledButton>
      </StyledPaper>
    </StyledContainer>
  );
}

export default IndustryReports;
