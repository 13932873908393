// src/pages/Alerts.jsx


import React, { useState } from 'react';
import { Typography, Box } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { MapContainer, TileLayer, Circle, Popup } from 'react-leaflet';
import { Helmet } from 'react-helmet';
import useAlertData from '../hooks/useAlertData';
import useTopCities from '../hooks/useTopCities';
import {
    RootContainer,
    HeaderPaper,
    MapSection,
    TerminalText,
    TopCitiesSection,
    StyledPagination,
    StyledButton
} from '../styles/AlertPageStyles';

const AlertPage = () => {
    const alerts = useAlertData();
    const topCities = useTopCities();
    const navigate = useNavigate();

    const [page, setPage] = useState(1);
    const alertsPerPage = 10;
    const totalPages = Math.ceil(alerts.length / alertsPerPage);

    const handleChangePage = (event, value) => {
        setPage(value);
    };

    const displayedAlerts = alerts.slice(
        (page - 1) * alertsPerPage,
        page * alertsPerPage
    );

    // Construct structured data (JSON-LD) for SEO
    const structuredData = {
        "@context": "https://schema.org",
        "@type": "ItemList",
        "name": "Cybercrime Alerts",
        "description": "A list of recent cybercrime alerts reported through CCRNet.",
        "itemListElement": alerts.map((alert, index) => ({
            "@type": "ListItem",
            "position": index + 1,
            "item": {
                "@type": "Article",
                "headline": alert.title,
                "description": alert.description,
                "datePublished": alert.date,
                "articleSection": "Cybercrime Alerts",
                "author": {
                    "@type": "Organization",
                    "name": "CCRNet"
                }
            }
        }))
    };

    return (
        <RootContainer>
            <Helmet>
                <title>Cybercrime Alerts - Stay Informed | CCRNet</title>
                <meta
                    name="description"
                    content="Stay informed with the latest cybercrime alerts worldwide. Explore real-time reports, top affected countries, and detailed insights into cyber threats from CCRNet."
                />
                <script type="application/ld+json">
                    {JSON.stringify(structuredData)}
                </script>
            </Helmet>

            {/* Back to Home Button */}
            <Box mb={2} display="flex" justifyContent="flex-start">
                <StyledButton onClick={() => navigate('/')}>
                    &larr; Back to Home
                </StyledButton>
            </Box>

            {/* Header */}
            <HeaderPaper>
                <Typography variant="h3" component="h1" gutterBottom>
                    Cybercrime Alerts
                </Typography>
                <Typography variant="h6" component="p">
                    Stay informed with the latest cybercrime alerts and safety information.
                </Typography>
            </HeaderPaper>

            {/* Global Map */}
            <MapSection>
                <MapContainer center={[20, 0]} zoom={2} style={{ height: '100%', width: '100%' }}>
                    <TileLayer
                        url="https://{s}.basemaps.cartocdn.com/dark_all/{z}/{x}/{y}{r}.png"
                        attribution='&copy; <a href="https://www.hiveforensics.com">Hive Forensics</a>'
                    />
                    {alerts.map((alert) => (
                        <Circle
                            key={alert.id}
                            center={alert.position}
                            radius={200000}
                            fillColor="#4caf50"
                            color="#4caf50"
                            weight={1}
                            fillOpacity={0.6}
                        >
                            <Popup>
                                <Typography variant="h6" component="h2" style={{ color: '#4caf50' }}>
                                    {alert.title}
                                </Typography>
                                <Typography variant="body2" style={{ color: '#bdbdbd' }}>
                                    {alert.date}
                                </Typography>
                                <Typography variant="body1" style={{ color: '#e0e0e0' }}>
                                    {alert.description}
                                </Typography>
                            </Popup>
                        </Circle>
                    ))}
                </MapContainer>
            </MapSection>

            {/* Top 5 Cities */}
            <TopCitiesSection>
                <Typography variant="h4" component="h2" gutterBottom>
                    Top 5 Countries with Most Reports
                </Typography>
                {topCities.map((cityData, index) => (
                    <TerminalText key={index} variant="body1" gutterBottom>
                        {`${index + 1}. ${cityData.city}: ${cityData.count} reports`}
                    </TerminalText>
                ))}
            </TopCitiesSection>

            {/* Alerts List */}
            <Box>
                {displayedAlerts.map((alert) => (
                    <TerminalText key={alert.id} variant="body1" gutterBottom>
                        {`[${alert.date}] ${alert.title}\n${alert.description}`}
                    </TerminalText>
                ))}
            </Box>

            {/* Pagination Controls */}
            <Box
                display="flex"
                justifyContent="center"
                mt={4}
                sx={{
                    position: 'sticky',
                    bottom: 0,
                    backgroundColor: '#121212',
                    padding: '16px',
                    zIndex: 1000,
                    boxShadow: '0px -2px 4px rgba(0, 0, 0, 0.5)',
                }}
            >
                <StyledPagination
                    count={totalPages}
                    page={page}
                    onChange={handleChangePage}
                    color="primary"
                />
            </Box>
        </RootContainer>
    );
};

export default AlertPage;