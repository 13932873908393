// src/App.js

import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Home from './pages/Home';
import Complaint from './pages/Complaint';
import PrivacyPolicy from './pages/PrivacyPolicy';
import FAQs from './pages/FAQs';
import AlertPage from './pages/Alerts';
import OtherCrimes from './pages/OtherCrimes';
import IndustryReports from './pages/IndustryReports';
import './App.css';
import 'leaflet/dist/leaflet.css';

function App() {
  return (
    <Router>
      <div className="App">
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/complaint" element={<Complaint />} />
          <Route path="/privacy-policy" element={<PrivacyPolicy />} />
          <Route path="/faqs" element={<FAQs />} />
          <Route path="/alerts" element={<AlertPage />} />
          <Route path="/other-crimes" element={<OtherCrimes />} />
          <Route path="/industry-reports" element={<IndustryReports />} />
          {/* Add more routes here if needed */}
        </Routes>
      </div>
    </Router>
  );
}

export default App;
