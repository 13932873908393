import { useMemo } from 'react';
import useAlertData from './useAlertData';
import coordinateToCountry from '../data/coordinateToCountry';

const useTopCities = () => {
    const alerts = useAlertData();

    const topCities = useMemo(() => {
        const cityCounts = alerts.reduce((acc, alert) => {
            const positionKey = `${alert.position[0].toFixed(4)},${alert.position[1].toFixed(4)}`;
            const city = coordinateToCountry[positionKey] || 'Unknown';

            acc[city] = (acc[city] || 0) + 1;
            return acc;
        }, {});

        return Object.entries(cityCounts)
            .sort((a, b) => b[1] - a[1])
            .slice(0, 5)
            .map(([city, count]) => ({ city, count }));
    }, [alerts]);

    return topCities;
};

export default useTopCities;
