// src/styles/DisclaimerStyles.js
import { styled } from '@mui/material/styles';
import { Container, Paper, Button } from '@mui/material';

export const RootContainer = styled(Container)(({ theme }) => ({
    padding: theme.spacing(2),
    backgroundColor: '#f5f5f5',
    [theme.breakpoints.up('sm')]: {
        padding: theme.spacing(4),
    },
}));

export const DisclaimerPaper = styled(Paper)(({ theme }) => ({
    padding: theme.spacing(3),
    textAlign: 'center',
    backgroundColor: '#ffffff',
    [theme.breakpoints.up('sm')]: {
        padding: theme.spacing(4),
    },
}));

export const AcceptButton = styled(Button)(({ theme }) => ({
    marginTop: theme.spacing(2),
    backgroundColor: '#004d40',
    color: '#fff',
    '&:hover': {
        backgroundColor: '#00332e',
    },
    width: '100%',
    [theme.breakpoints.up('sm')]: {
        width: 'auto',
    },
}));
